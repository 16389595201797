import React, {useEffect, useState} from 'react';
import Newsitem from './Newsitem';
import Spinner from './Spinner';
import PropTypes from 'prop-types';
import InfiniteScroll from "react-infinite-scroll-component";



const News =(props)=> {
 const [articles, setArticles] = useState([])
 const [page, setPage] = useState(1)
 const [loading, setLoading] = useState(true)
 const [totalResults, setTotalResults] = useState(0)
   
 const capitalizeFirstLetter =(string)=> {
   return string.charAt(0).toUpperCase() + string.slice(1);
  }
    
    
    const updateNews = async ()=>{
      props.setProgress(10)
      let url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=427435ee928a4a8bade4cfc1b34c623f&page=${page}&pageSize=${props.pageSize}`
      setLoading(true)
      let data = await fetch(url)
      let parsedData = await data.json()
      setArticles(parsedData.articles)
      setTotalResults(parsedData.totalResults)
      setLoading(false)
      props.setProgress(100)
    }
    
   useEffect(() => {
     document.title = `${capitalizeFirstLetter(props.category)} - NewsMonkey`
     updateNews()
     // eslint-disable-next-line
   },[])


   const fetchMoreData = async () => {
     let url = `https://newsapi.org/v2/top-headlines?country=${props.country}&category=${props.category}&apiKey=427435ee928a4a8bade4cfc1b34c623f&page=${page+1}&pageSize=${props.pageSize}`
     setPage(page+1)
    let data = await fetch(url)
    let parsedData = await data.json()
    setArticles(articles.concat(parsedData.articles))
    setTotalResults(parsedData.totalResults)
  };

  // const handlePrevtoggle = async ()=>{
  //   setPage(page-1)
  //   updateNews()
  // }

  // const handleNexttoggle = async ()=>{
  //   setPage(page+1)  
  //   updateNews() 
  //   }


        return (
          <>
          <h1 className='text-center my-5' style={{marginTop: "150px"}}>NewsMonkey - Top {capitalizeFirstLetter(props.category)} Headlines</h1>
          {loading && <Spinner/>}
          <InfiniteScroll
          dataLength={articles.length}
          next={fetchMoreData}
          hasMore={articles.length !== totalResults}
          loader={<Spinner/>}>

          <div className="container">
          <div className="row">
            { articles.map((element)=>{
              return <div className="col-md-4" key={element.url}>
                <Newsitem title={element.title?element.title:""} description={element.description?element.description.slice(0,88):""} imgUrl={element.urlToImage?element.urlToImage:"https://thumbs.dreamstime.com/b/news-newspapers-folded-stacked-word-wooden-block-puzzle-dice-concept-newspaper-media-press-release-42301371.jpg"} newsUrl={element.url} author={element.author} date={element.publishedAt} source={element.source.name}/>
            </div>
            })}
          </div>
          </div>
          </InfiniteScroll>
          {/* <div className="container d-flex justify-content-between">
          <button disabled={state.page<=1} onClick={handlePrevtoggle} className="btn btn-dark " type="button" >&larr; Previous</button>
          <button disabled={(state.page + 1 > Math.ceil(state.totalResults/(props.pageSize)))} onClick={handleNexttoggle} className="btn btn-dark " type="button" >Next &rarr;</button>
           </div> */}
         </>
        )
    
}

 News.defaultProps ={
  country: "in",
  pageSize: 8,
  category: "general"
}
 News.PropsTypes ={
  country: PropTypes.string,
  pageSize: PropTypes.number,
  category: PropTypes.string
}

export default News;