import './App.css';
import React, {useState} from 'react';
import Navbar from './components/Navbar';
import News from './components/News';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'



const App =()=> {
  const [progress, setProgress] = useState(0)
  
    return (
  
       <BrowserRouter>
       <Navbar />
       <LoadingBar
       height={2}
        color='#f11946'
        progress={progress}
      />
         <Routes>
           <Route>
           <Route  index element={<News setProgress={setProgress} pageSize={6}/>} />
           <Route  path="business" element={<News setProgress={setProgress}  key="business" country="in" pageSize={6} category="business"/>} />
           <Route  path="entertainment" element={<News setProgress={setProgress}  key="entertainment" country="in" pageSize={6} category="entertainment"/>} />
           <Route  path="general" element={<News setProgress={setProgress}  key="general" country="in" pageSize={6} category="general"/>} />
           <Route  path="health" element={<News setProgress={setProgress}  key="health" country="in" pageSize={6} category="health"/>} />
           <Route  path="science" element={<News setProgress={setProgress}  key="science" country="in" pageSize={6} category="science"/>} />
           <Route  path="sports" element={<News setProgress={setProgress}  key="sports" country="in" pageSize={6} category="sports"/>} />
           <Route  path="technology" element={<News setProgress={setProgress}  key="technology" country="in" pageSize={6} category="technology"/>} />
           </Route>
         </Routes>
       </BrowserRouter>
    )
  }


export default App;
