import React from "react";

const Newsitem =(props)=> {
    let { title, description, imgUrl, newsUrl, author, date, source } =
      props;
    return (
      <div className="containe my-4">
        <div className="card">
          <div style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "absolute",
                right:"0"
              }}
          >
            <span
              className="badge rounded-pill bg-danger"
               >
              {source}
            </span>
          </div>
          <img className="card-img-top" src={imgUrl} alt="Card cap" />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
            <p className="card-text">{description}</p>
            <p className="card-text">
              <small className="text-body-secondary">
                by {author ? author : "unknown"} on{" "}
                {new Date(date).toGMTString()}
              </small>
            </p>
            <a
              href={newsUrl}
              target="_blank"
              rel="noreferrer"
              className="btn btn-sm btn-dark"
            >
              Read more
            </a>
          </div>
        </div>
      </div>
    );
  
}

export default Newsitem;
